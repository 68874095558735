<template>
    <div>
        <div class="tabbar">
            <div class="report-title">
                <div class="goback" @click="toBack">
                    <el-image  :src="require('@/assets/images/back.png')"></el-image>
                </div>
                <div class="title">{{ report.scaleName }}</div>
            </div>
        </div>
        <div class="report">
            <div class="report-result">
                <div class="rpt-rslt">
                    <div class="rpt-rslt-score">得分：{{report.countDesc}}</div>
                    <div class="rpt-rslt-result">结果：<p :class="report.isNormal == 0 ? 'unnormal':''">{{ report.item.itemDesc }}</p></div>
                </div>
            </div>
            <div class="qstes">
                <div class="qst-item" v-for="(item,index) in questions">
                    <div class="qst-cnt">
                        <p>{{index+1}}.{{item.qstTitle}}</p>
                    </div>
                    <div class="qst-answer">
                        {{ item.showValue }}
                    </div>
                </div>
            </div>
            <div class="judge-cnts">
                <div class="flex-flow">评分标准:</div>
                <div class="judge-items" v-for="(item,index) in judgeItems">
                    <div class="judge-item">
                        <!-- <div>0≤评分≤1；认知功能正常</div> -->
                        <div>{{ item.itemName+';'+item.itemDesc }}</div>
                    </div>
                </div>
            </div>
        </div>
    </div>
</template>


<script>

import {addRecord,listQuestionsByScaleId,listJudgesByScaleId} from '@/api/common'
    export default {
        data(){
         return {
            report:{
                countDesc:'',
                item:{
                    itemDesc:''
                }
            },
            questions:[],
            scaleId:'',
            checkedRadios:[],
            judgeItems:[],
            deptcode:''
         }
        },
        mounted(){
            
            let data = this.$route.query['data'];
            
            this.scaleId = data.scaleId;
      
            if(data.isSave == 1){
                this.calc(data)
            }else{
                let results = JSON.parse(localStorage.getItem('results'))
                if(results != null){
                    let filters = results.filter(item => item.scaleId == this.scaleId);
                    if(filters != null){
                        this.report = filters[0];
                        this.questions = filters[0].questions;
                    }
                }
            }
            
            this.getJudgeItems();
        },  
        methods: {
            toBack(){
                    
                let depcode = localStorage.getItem("deptcode");
                let url = '/list/'+depcode+'/1';
                this.$router.push(url)
            },
            calc(value){
                let data = value;
                
                let user = JSON.parse(localStorage.getItem("user"));
                let batchNum = localStorage.getItem("batchNum");
                let deptcode = localStorage.getItem("deptcode");
                this.deptcode = deptcode;

                if(user != null && user != undefined){
                    data.agedName = user.agedName;
                    data.agedPhone = user.agedPhone;
                }
                data.batchNum = batchNum;
                data.deptId  = deptcode;
                
                

                addRecord(data).then(res=>{
                    this.report = res.data.data;
                    this.questions = res.data.data.questions;
                    
                    let results = JSON.parse(localStorage.getItem('results'))
                    if(results == null){
                        results = []
                    }
                    
                    results.push(this.report);
                    localStorage.setItem('results',JSON.stringify(results))

                    let scales = JSON.parse(localStorage.getItem('scales'))
                    if(scales!=null){
                        let finds = scales.filter(item => item.id === this.scaleId);
                        if(finds!=null){
                            finds[0].mark=1;
                            finds[0].isFinished = 1;
                        }
                        localStorage.setItem('scales',JSON.stringify(scales))
                    }
                })

                listQuestionsByScaleId(data.scaleId).then(res=>{
                    this.questions = res.data.data.questions;
                })   
            },
            getQuestions(){
                
                listQuestionsByScaleId(this.scaleId).then(res=>{
                    this.questions = res.data.data.questions;
                })   
            },
            getJudgeItems(){
                listJudgesByScaleId(this.scaleId).then(res=>{
                    this.judgeItems  = res.data.data;
                })
            }
        },
    }

</script>


<style scoped>
.tabbar{
    position: fixed;
    left: 0;
    top: 0.5rem;
    width: 100%;
    border-radius: 1rem;
}

.goback{
    height: 2rem;
    width: 2rem;
    margin: 0 1rem;
}
.report-title{
    height: 5rem;
    line-height: 5rem;
    display: flex;
    align-items: center;
    background-color: #365EF1;
    color: #fff;
   font-size: 1.25rem;
}

.report{
  width: 100%;
  margin-top: 7rem;
}
.report-result{
  border-radius: 1rem;
  color: #fff;
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
  font-size: 1.25rem;
}

.rpt-rslt{
    box-sizing: border-box;
    width: 90%;
    background-color: #54A6FC;
    border-radius: 0.8rem;
    height: 7rem;
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    justify-content: center;
    padding-left: 1.25rem;
}

.rpt-rslt-score,.rpt-rslt-result{
    height: 3rem;
    line-height: 3rem;
}

.rpt-rslt-result{
    display: flex;
    align-items: center;
    justify-content: center;
}
.unnormal{
    color: red;
}

.qstes{
  box-shadow: 0 2px 12px 0 rgba(0, 0, 0, .1);
  border-radius: 4px;
  border: 1px solid #ebeef5;
  background-color: #fff;
  padding: 0 2rem;
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  margin: 1rem 1rem 0 1rem;
}

.qst-item{
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    padding-bottom: 0.5rem;
}
.qst-cnt p{
    text-align: left;
}
.qst-answer{
  margin-top: 0.8rem;
  color: #54A6FC;
}




.judge-cnts{
  margin: 1rem;
  color: #9e9e9e;
  /* width: calc(100% - 8rem); */
  padding: 1rem 2rem;
  box-shadow: 0 2px 12px 0 rgba(0, 0, 0, .1);
  border-radius: 4px;
  border: 1px solid #ebeef5;
  background-color: #fff;
}
.flex-flow{
    display: flex;
}
.judge-items{
    display: flex;
    margin-top: 0.8rem;
}
.judge-item{
  color: #9e9e9e;
}

</style>