<template>
  <div class="home">
    <div class="zc-title">
      <div>老年综合评估自测</div>
      <div class="icon-img">
        <el-dropdown trigger="click" @command="handleCommand">
          <el-image class="icon-img2"  :src="require('@/assets/images/add.png')"></el-image>
          <el-dropdown-menu slot="dropdown">
            <el-dropdown-item command="addAssesser">添加评估对象</el-dropdown-item>
          </el-dropdown-menu>
        </el-dropdown>
      </div>
    </div>
    <div>
      <div>
        <div class="content-title">
          <div><i class="icon iconfont icon-lingdang" style='color:#FCB837'></i></div>
          <div class="content-desc">评估内容</div>
        </div>
        <div>
          <div class="scale-card" v-for="(item,index) in scales" @click="toQuestions(item)">
            <div class="scale-info">
              <div>
                <i class="icon iconfont icon-choose" style="color:green;" v-if="item.mark == 1 ? true:false"></i>
              </div>
              <div class="scale-name">
                {{ item.scaleName }}
              </div>
            </div>
            <div style="color: green;" v-if="item.mark == 1 ? true:false">完成</div>
            <div style="color: #9e9e9e;" v-if="item.mark == 1 ? false:true">未完成</div>
          </div>
          
        </div>
      </div>
      <div class="btns">
        <div class="btn" v-if="!assessFinished" @click="btnStartAssess">开始评估</div>
        <div class="btn" v-if="assessFinished" @click="toReport">报告查看</div>
      </div>
    </div>
    <!-- <div v-if="isInit" class="lgin">
      <div class="login-view">
        <el-form ref="form" :rules="rules" :model="form" label-width="5rem">
          <el-form-item label="被评估人" prop="agedName">
            <el-input v-model="form.agedName" placeholder="请输入被评估人名称(必填)"></el-input>
          </el-form-item>
          <el-form-item label="手机号" prop="agedPhone">
            <el-input v-model="form.agedPhone" placeholder="请输入手机号(必填)"></el-input>
          </el-form-item>
          
          <el-form-item>
            <el-button type="primary" @click="btnSaveBaseInfo">开始评估</el-button>
          </el-form-item>
        </el-form>
      </div>
    </div> -->
    <el-dialog
      title="添加评估人"
      :visible.sync="dialogVisible"
      width="90%">
      <div class="lgin">
        <div class="login-view">
          <el-form ref="form" :rules="rules" :model="form" label-width="5rem">
            <el-form-item label="被评估人" prop="agedName">
              <el-input v-model="form.agedName" placeholder="请输入被评估人名称(必填)"></el-input>
            </el-form-item>
            <el-form-item label="手机号" prop="agedPhone">
              <el-input v-model="form.agedPhone" placeholder="请输入手机号(必填)"></el-input>
            </el-form-item>
          </el-form>
        </div>
      </div>
      <span slot="footer" class="dialog-footer">
        <el-button @click="dialogVisible = false">取 消</el-button>
        <el-button type="primary" @click="btnSaveBaseInfo">确定</el-button>
      </span>
    </el-dialog>
  </div>
</template>

<script>
import {listScales,listQuestions,addRecord,addAgedPeople} from '@/api/common'
import {createBatchNum} from '@/utils/tool'

export default {
  name: 'HomeView',
  data(){
    return {
      scales:[],
      assessFinished:false,
      isInit:true,
      form:{},
      dialogVisible:false,
      rules: {
        agedName: [
          { required: true, message: '请输入被评估人名称', trigger: 'blur' }
        ],
        agedPhone: [
          { required: true, message: '请输入手机号', trigger: 'blur' }
        ]
      },
      tipmsg:'请先点击右上角 + 添加评估对象~'
    }
  },
  mounted(){
    
    let deptCode = this.$route.params['deptCode']
    let status = this.$route.params['status']
    
    if(deptCode == null || deptCode === ''){
      // alert('医院编码deptCode参数输入错误');
      this.$message({
          message: '医院编码deptCode参数输入错误',
          type: 'warning'
        });
      return;
    }
    
    localStorage.removeItem('deptcode')
    localStorage.setItem("deptcode",deptCode);
    this.deptCode = deptCode;

    //等于0是初次进入，需要重新获取
    if(status == 0){
      this.isInit = true;
      localStorage.removeItem('scales')
      localStorage.removeItem('user')
      localStorage.removeItem('results')
      localStorage.removeItem('batchNum')
      localStorage.setItem("batchNum",createBatchNum());
      this.getList();
    }else{
      this.isInit = false;
        this.scales = JSON.parse(localStorage.getItem('scales'))
        if(this.scales != null){
          let finished = this.scales.filter(item=>item.mark == 1);
          if(finished != null){
            this.assessFinished  = finished.length == this.scales.length;
          }
        }
    }
  },
  methods:{
    getList(){
      
      listScales(this.deptCode).then(res=>{
        this.scales = res.data.data;
        localStorage.setItem('scales', JSON.stringify(this.scales))
      })

    },
    //显示问题
    toQuestions(scale){
      let user = localStorage.getItem('user');
      if(user == null){
        this.$message.error(this.tipmsg);
        return;
      }
      if(scale.isFinished == 1){
        this.$router.push({path:'/selfreport',query:{
          data:{
            scaleId:scale.id,
            isSave:0
          }
        }})
      }else{
        this.$router.push({path:'/qst',query:{
          scaleId:scale.id,
          scaleName:scale.scaleName,
          allCount:scale.questions.length
        }});
      }
    },
    //显示报告
    toReport(){
      this.$router.push('/report')
    },
    //开始评估
    btnStartAssess(){
      let user = localStorage.getItem('user');
      if(user == null){
        this.$message.error(this.tipmsg);
        return;
      }

      let scales = JSON.parse(localStorage.getItem("scales"));

      if(scales != null && scales.length>0){
        for(let index = scales.length - 1; index >= 0; index--){
          let scale = scales[index];
          if(scale.mark != 1){
            this.$router.push({path:'/qst',query:{
              scaleId:scale.id,
              scaleName:scale.scaleName,
              allCount:scale.questions.length
            }});
          }
        }
      }
    },
    //保存基本信息
    btnSaveBaseInfo(){
      this.$refs['form'].validate((valid) => {
        
        if (valid) {
          this.isInit = true;
          let data = {
            agedName:this.form.agedName,
            agedPhone:this.form.agedPhone
          }
          addAgedPeople(data).then(res=>{
            this.isInit = false;
            localStorage.setItem('user',JSON.stringify(data))
            this.dialogVisible  = false;
          })
        }
      })
    },
    //添加评估对象
    handleCommand(cmd){
      this.dialogVisible = true;
    },
  }
}
</script>

<style scoped>

.zc-title{
  display: flex;
  align-items: center;
  justify-content: center;
  height: 5rem;
  line-height: 5rem;
  background-color: #1A4DCA;
  color: #fff;
  font-size: 1.25rem;
}
.icon-img{
  position: absolute;
  top:1.5rem;
  right: 0.5rem;
  width: 2rem;
  height:2rem;
}
.el-dropdown-menu{
  position: absolute !important;
  top: 3.5rem !important;
  right: 2rem !important;
  width: 8rem !important;

}
.content-title{
  display: flex;
  margin-left: 1.25rem;
  margin-top: 1.25rem;
  height: 2rem;
  line-height: 2rem;
  font-size: 1.25rem;
}
.lgin{
  display: flex;
  align-items: center;
  justify-content: center;
}
.login-view{
  margin-top: 2rem;
  margin-left: -2rem;
  padding: 0 0.625rem  0 0;
}
.scale-info{
  display: flex;
  align-items: center;

}



.scale-name{
  margin-left: 0.3rem;
  /* font-size: 1.2rem; */
}

@media screen and (max-width: 322px){
  .scale-name{
    font-size: 0.8rem;
  }
}


@media screen and (max-width: 344px){
  .scale-name{
    font-size: 1rem;
  }
}

@media screen and (max-width: 380px) {
  .scale-name{
    font-size: 1.1rem;
  }
}

@media screen and (min-width: 381px) {
  .scale-name{
    font-size: 1.2rem;
  }
}

.content-desc{
  margin-left: 0.3rem;
}

.scale-card{
  padding: 0 1rem 0 0.6rem;
  display: flex;
  align-items: center;
  justify-content: space-between;
  height: 5rem;
  line-height: 5rem;
  margin: 0.5rem 1.25rem 0 1.25rem;
  box-shadow: 0 2px 12px 0 rgba(0, 0, 0, .1);
  font-size: 1rem;
  border-radius: 0.7rem;
  border: 1px solid #ebeef5;
  background-color: #fff;
  
}


.btns{
  margin-top: 5rem;
  display: flex;
  align-items: center;
  justify-content: center;

  height: 2rem;
  line-height: 2rem;
}
.btn{
  background-color: #54A6FC;
  width: 80%;
  height: 4rem;
  line-height: 4rem;
  border-radius: 0.5rem;
  color: #fff;
  font-size: 1.25rem;
}

</style>